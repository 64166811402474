@import '../../../styles/variables'

.text
  font-family: 'FuturaDemiC', sans-serif
  color: $black
  font-size: 2rem

.icon
    margin-right: 1.5rem
    width: 2.5rem
    height: 2.5rem

.container
  display: flex
  align-items: center
  &:not(:last-child)
    margin-bottom: 2.5rem