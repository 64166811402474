@import "../../../styles/variables"

.step
  width: 100%
  margin: 0
  cursor: pointer
  background: none
  display: block
  &:hover
    .step__line
      background: $main-dark
    .inactive
      background: $grey
    .step__text
      color: $black
  &__text
    color: $grey
    font-size: 1.8rem
    text-align: center
    display: block
    line-height: 120%
    margin-bottom: .5rem
    align-items: end
  &__line
    height: 1rem
    width: 100%
    border-radius: 3rem
    background: $main
    transition: all .2s

.current
  color: $black

.inactive
  cursor: default
  .step__line
    background: rgba($grey, .5)
  &:hover
    .step__line
      background: $grey
  &:active
    transform: translateY(0)
