.input {
  border-radius: 1rem;
  border: 0.1rem solid #818181;
  outline: none;
  background: #ffffff;
  height: auto;
  width: 100%;
  padding: 1.5rem;
  font-family: "FuturaLightC", sans-serif;
  font-size: 2.2rem;
}
.input:focus {
  border: 0.1rem solid #69B5FC;
  box-shadow: 0 0 0.4rem 0 #69B5FC;
}

.valid {
  padding: 1.5rem 4.5rem 1.5rem 1.5rem;
  border: 0.1rem solid #33B589;
  background: url("../../../static/images/valid.svg") no-repeat 96% center;
  background-size: 2rem 2rem;
}
.valid:focus {
  box-shadow: 0 0 0.4rem 0 #33B589;
  border: 0.1rem solid #33B589;
}

.invalid {
  padding: 1.5rem 4.5rem 1.5rem 1.5rem;
  border: 0.1rem solid #F54758;
  background: url("../../../static/images/invalid.svg") no-repeat 96% center;
  background-size: 2rem 2rem;
}
.invalid:focus {
  box-shadow: 0 0 0.4rem 0 #F54758;
  border: 0.1rem solid #F54758;
}

.number {
  width: 16.6rem;
  font-size: 2rem;
}

