.general_questions {
  min-height: calc(100vh - 46.8rem);
  margin-bottom: 8rem;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

