.person {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5rem;
}
@media (max-width: 650px) {
  .person {
    flex-direction: column-reverse;
  }
}
.person__photo {
  border-radius: 3rem;
  width: 30rem;
  height: auto;
  cursor: default;
  user-select: none;
}
@media (max-width: 650px) {
  .person__photo {
    width: 70%;
    margin: 0 auto 5rem auto;
  }
}
@media (max-width: 650px) {
  .person__quote {
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
}
.person__quote_text {
  padding: 3rem;
  color: #ffffff;
  border-radius: 3rem;
  margin-bottom: 3rem;
  transition: all 0.3s;
  font-weight: normal;
  font-size: 3.2rem;
}
.person__quote_name {
  margin-left: 3rem;
  font-weight: normal;
  font-size: 3.2rem;
}
@media (max-width: 650px) {
  .person__quote_name {
    text-align: center;
    margin: 0 0 2rem 0;
  }
}

.blue {
  background: #69B5FC;
}
@media (hover: hover) and (pointer: fine) {
  .blue:hover {
    background: #0C7ADF;
  }
}

.green {
  background: #33B589;
}
@media (hover: hover) and (pointer: fine) {
  .green:hover {
    background: #237E5F;
  }
}

.margin_right {
  margin-right: 7rem;
}
@media (max-width: 650px) {
  .margin_right {
    margin-right: auto;
  }
}

.position {
  flex-direction: row;
}
@media (max-width: 650px) {
  .position {
    flex-direction: column;
  }
}

.margin_bottom {
  margin-bottom: 8rem;
}

