.main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8rem;
}
@media (max-width: 800px) {
  .main {
    flex-direction: column-reverse;
    margin-bottom: 5rem;
  }
}

.main__text {
  width: 32%;
}
@media (max-width: 1150px) {
  .main__text {
    width: 37%;
  }
}
@media (max-width: 800px) {
  .main__text {
    width: 80%;
  }
}
@media (max-width: 500px) {
  .main__text {
    width: 100%;
  }
}

.h2__align_left {
  text-align: left;
  margin-bottom: 3.3rem;
}
@media (max-width: 500px) {
  .h2__align_left {
    margin-bottom: 2rem;
  }
}

.h1 {
  font-size: 5rem;
  font-weight: normal;
  font-family: "FuturaDemiC", sans-serif;
  line-height: 6rem;
  text-align: left;
  margin-bottom: 3.3rem;
}
@media (max-width: 500px) {
  .h1 {
    margin-bottom: 2rem;
  }
}

.main__p {
  margin-bottom: 3.3rem;
  font-size: 2.3rem;
}
@media (max-width: 500px) {
  .main__p {
    margin-bottom: 2rem;
  }
}

.main__photo {
  width: 60%;
  height: auto;
  cursor: default;
  filter: drop-shadow(4px 4px 4px rgba(0, 0, 0, 0.25));
}
@media (max-width: 800px) {
  .main__photo {
    margin-bottom: 3.3rem;
    width: 80%;
  }
}
@media (max-width: 500px) {
  .main__photo {
    width: 100%;
  }
}

