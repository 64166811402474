.group {
  display: inline-block;
  overflow: hidden;
}

.group__item {
  display: inline-block;
  float: left;
}

.group input[type=radio] {
  display: none;
}

.group label {
  display: inline-block;
  cursor: pointer;
  padding: 1.5rem 2rem;
  border: 0.1rem solid #818181;
  border-right: none;
  user-select: none;
  font-family: "FuturaLightC", sans-serif;
  font-size: 2.2rem;
  transition: all 0.2s;
}
@media (max-width: 500px) {
  .group label {
    font-size: 2.5rem;
  }
}

.group .group__item:first-child label {
  border-radius: 1rem 0 0 1rem;
}

.group .group__item:last-child label {
  border-radius: 0 1rem 1rem 0;
  border-right: 0.1rem solid #818181;
}

/* Checked */
.group input[type=radio]:checked + label {
  background: #69B5FC;
  color: #ffffff;
}

/* Hover */
.group label:hover {
  background: #E2EDF7;
}

/* Disabled */
.group input[type=radio]:disabled + label {
  background: #ffffff;
  color: #818181;
}

