@import "../../../styles/variables"

.photo
  border-radius: 3rem
  width: 23rem
  height: 23rem
  grid-column: 1/2
  grid-row: 1/-1
  align-self: start
  @media (max-width: 600px)
    grid-row: 1/3

.about
  font-size: 2.2rem
  @media (max-width: 600px)
    grid-row: 3/4
    grid-column: 1/-1

.card
  display: grid
  grid-template-columns: max-content 2fr 1fr
  grid-template-rows: repeat(3, max-content)
  align-items: center
  grid-gap: 2rem 4rem
  padding: 5rem 0 4rem 0
  cursor: pointer
  color: $black
  &:hover
    background: rgba($blue-light, .5)
  @media (max-width: 800px)
    grid-template-columns: max-content 1fr
    grid-template-rows: repeat(5, max-content)
    align-items: flex-start
    grid-gap: 2rem


.button
  display: none
  @media (max-width: 800px)
    display: block
  @media (max-width: 600px)
    grid-column: 1/-1
    grid-row: -2/-1

.name
  font-size: 3.2rem
  font-weight: normal
