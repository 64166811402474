@import "../../../styles/variables"

.person
  display: flex
  align-items: center
  justify-content: space-between
  margin-bottom: 5rem
  @media (max-width: 650px)
    flex-direction: column-reverse
  &__photo
    border-radius: 3rem
    width: 30rem
    height: auto
    cursor: default
    user-select: none
    @media (max-width: 650px)
      width: 70%
      margin: 0 auto 5rem auto
  &__quote
    @media (max-width: 650px)
      width: 90%
      margin: 0 auto
      text-align: center
    &_text
      padding: 3rem
      color: $white
      border-radius: 3rem
      margin-bottom: 3rem
      transition: all .3s
      font-weight: normal
      font-size: 3.2rem
    &_name
      margin-left: 3rem
      font-weight: normal
      font-size: 3.2rem
      @media (max-width: 650px)
        text-align: center
        margin: 0 0 2rem 0

.blue
  background: $main
  @media (hover: hover) and (pointer: fine)
    &:hover
      background: $main-dark

.green
  background: $contrast
  @media (hover: hover) and (pointer: fine)
    &:hover
      background: $contrast-dark

.margin_right
  margin-right: 7rem
  @media (max-width: 650px)
    margin-right: auto

.position
  flex-direction: row
  @media (max-width: 650px)
    flex-direction: column

.margin_bottom
  margin-bottom: 8rem
