.footer {
  background: #E2EDF7;
  padding: 5rem 0;
}
@media (max-width: 1150px) {
  .footer {
    padding: 5rem 2rem;
  }
}

.hr {
  border: none;
  border-top: 0.1rem solid #ffffff;
}

.content {
  padding: 3rem 0;
  display: grid;
  grid-column-gap: 4rem;
  grid-template-columns: repeat(4, max-content) 1fr;
  align-items: center;
}
@media (max-width: 1020px) {
  .content {
    grid-template-columns: repeat(2, auto) max-content;
    grid-template-rows: repeat(2, max-content);
    grid-row-gap: 2rem;
  }
  .content .company {
    grid-column: -2/-1;
    grid-row: 1/-1;
  }
}
@media (max-width: 500px) {
  .content {
    grid-template-columns: max-content 1fr;
    grid-template-rows: repeat(4, max-content);
  }
}
@media (max-width: 400px) {
  .content {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(5, max-content);
    justify-items: center;
    grid-row-gap: 2rem;
  }
  .content .company {
    grid-column: 1/-1;
    grid-row: -2/-1;
  }
}

.a {
  font-size: 2rem;
  text-decoration: none;
  cursor: pointer;
  color: #818181;
}
@media (max-width: 500px) {
  .a {
    font-size: 2.2rem;
  }
}

.company {
  font-size: 2rem;
  text-align: right;
  user-select: none;
}

