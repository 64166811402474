.cards
  display: grid
  grid-template-columns: repeat(3, 1fr)
  grid-template-rows: repeat(2, max-content)
  gap: 2rem
  margin-bottom: 8rem
  @media (max-width: 800px)
    grid-template-columns: repeat(2, 1fr)
    grid-template-rows: repeat(3, max-content)
  @media (max-width: 600px)
    grid-template-columns: 1fr
    grid-template-rows: repeat(6, max-content)

.problems__header
  font-size: 5rem
  font-weight: normal
  font-family: "FuturaDemiC", sans-serif
  line-height: 6rem
  text-align: center
  margin-bottom: 8rem
  @media (max-width: 600px)
    margin-bottom: 5rem
