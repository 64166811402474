@import '../../../styles/variables'

.radio_group
  grid-template-columns: auto auto
  grid-row-gap: 1rem
  grid-column-gap: 1rem
  transition: all 0.4s ease
  max-height: 0
  opacity: 0.5
  &__visible
    display: grid
    max-height: max-content
    opacity: 1
  &__invisible
    display: none
  @media (max-width: 500px)
    grid-template-columns: auto

.radio_group label
  cursor: pointer
  font-family: 'FuturaLightC', sans-serif
  font-size: 2.2rem
  transition: all .2s
  @media (max-width: 500px)
    font-size: 2.5rem

.radio_group__item input
  width: 1.8rem
  height: 1.8rem
  margin-right: 1.2rem
  accent-color: $main-dark
