.photo__container
  display: grid
  grid-template-columns: repeat(auto-fit, minmax(20rem, auto))
  grid-row-gap: 2rem
  grid-column-gap: 1rem
  @media (max-width: 620px)
    grid-column-gap: 0

.photo
  width: 20rem
  height: auto

.modal_open
  position: fixed
  left: 50%
  transform: translateX(-50%)
  width: 100%
  top: 0