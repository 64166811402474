.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo_slider {
  width: auto;
  height: 60vh;
}
@media (max-width: 950px) {
  .photo_slider {
    height: 50vh;
  }
}
@media (max-width: 800px) {
  .photo_slider {
    height: 40vh;
  }
}
@media (max-width: 700px) {
  .photo_slider {
    height: 35vh;
  }
}

.slide__container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4rem;
  user-select: none;
}

