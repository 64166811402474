.features {
  grid-column: -2/-1;
  grid-row: 1/-1;
  align-self: start;
}
@media (max-width: 800px) {
  .features {
    grid-row: 2/3;
  }
}

.feature {
  display: flex;
  align-items: center;
}
.feature:not(:last-child) {
  margin-bottom: 2.5rem;
}
.feature__icon {
  margin-right: 1.5rem;
  width: 2.5rem;
  height: 2.5rem;
}
.feature__text {
  font-family: "FuturaDemiC", sans-serif;
  color: #000000;
  font-size: 2rem;
}

