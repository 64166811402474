@import '../../../styles/variables'

.question
  color: $black
  font-size: 2.2rem
  margin-bottom: 1.5rem
  @media (max-width: 500px)
    font-size: 2.5rem
  &__type
    font-size: 2rem
    @media (max-width: 500px)
      font-size: 2.2rem

.warning
  font-size: 1.5rem
  margin-bottom: 5rem
  margin-top: -3rem

.error
  color: $red
