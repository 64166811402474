@import "../../../styles/variables"

.questions
  margin: 0 auto 8rem auto
  width: 80%
  hr
    border: none
    border-top: .1rem solid rgba($grey, .5)
    margin-bottom: 2.5rem

.header
  font-size: 5rem
  font-weight: normal
  font-family: "FuturaDemiC", sans-serif
  line-height: 6rem
  text-align: center
  margin-bottom: 8rem
