.text {
  font-size: 2rem;
}
.text_bold {
  font-family: FuturaDemiC, sans-serif;
  color: #000000;
}

.title {
  color: #000000;
  font-size: 2rem;
}

