@import '../../../styles/variables'

.message__container
  text-align: center
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  margin-bottom: 5rem
  min-height: calc(100vh - 36rem)
  @media (max-width: 500px)
    min-height: calc(100vh - 34rem)
  @media (max-width: 400px)
    min-height: calc(100vh - 42.5rem)

.icon
  margin: 0 0 3rem 0
  width: 10rem
  height: auto
  cursor: default

.text
  width: 70%
  @media (max-width: 400px)
    font-size: 2.8rem
    width: 90%

.email
  font-family: 'FuturaMediumC', sans-serif

