.card {
  border-radius: 3rem;
  transition: all 0.5s;
}
@media (hover: hover) and (pointer: fine) and (pointer: fine) {
  .card:hover {
    transform: scale(1.05);
  }
}
@media (max-width: 800px) {
  .card {
    text-align: center;
  }
}

.green {
  background: #33B589;
  padding: 3rem 4rem;
}

.blue_light {
  background: #E2EDF7;
  padding: 2rem 2.5rem;
}
@media (hover: hover) and (pointer: fine) {
  .blue_light:hover {
    background: #cfe1f8;
  }
}

.h3 {
  font-size: 3.2rem;
  font-weight: normal;
}
.h3__green {
  color: #ffffff;
  font-size: 3.6rem;
  margin-bottom: 4.5rem;
}
@media (max-width: 800px) {
  .h3__green {
    margin-bottom: 2.5rem;
  }
}
.h3__blue_light {
  margin-bottom: 1.5rem;
  font-size: 2.6rem;
}

.p__green {
  color: #ffffff;
  font-size: 2rem;
  margin-bottom: 7rem;
  width: 80%;
}
@media (max-width: 800px) {
  .p__green {
    margin-bottom: 2.5rem;
    width: 100%;
  }
}
.p__blue_light {
  font-size: 2rem;
}

.card__1 {
  grid-column: 1/2;
  grid-row: 1/-1;
}
.card__2 {
  grid-column: 2/3;
  grid-row: 1/2;
}
.card__3 {
  grid-column: 2/3;
  grid-row: 2/3;
}

@media (max-width: 800px) {
  .card__1 {
    grid-column: 1/-1;
    grid-row: 1/2;
  }
  .card__2 {
    grid-column: 1/-1;
    grid-row: 2/3;
  }
  .card__3 {
    grid-column: 1/-1;
    grid-row: -2/-1;
  }
}

