.question {
  color: #000000;
  font-size: 2.2rem;
  margin-bottom: 1.5rem;
}
@media (max-width: 500px) {
  .question {
    font-size: 2.5rem;
  }
}
.question__type {
  font-size: 2rem;
}
@media (max-width: 500px) {
  .question__type {
    font-size: 2.2rem;
  }
}

.warning {
  font-size: 1.5rem;
  margin-bottom: 5rem;
  margin-top: -3rem;
}

.error {
  color: #F54758;
}

