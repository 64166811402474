.navbar {
  display: grid;
  grid-template-columns: 1fr 14rem;
  align-items: center;
  padding: 2.5rem 0 0 0;
  margin-bottom: 5rem;
}
@media (max-width: 500px) {
  .navbar {
    margin-bottom: 4rem;
  }
}

.location {
  display: flex;
  align-items: center;
  user-select: none;
  justify-self: end;
}
.location__text {
  font-size: 1.9rem;
  font-weight: normal;
}
.location__icon {
  cursor: default;
  width: 2rem;
  height: auto;
  margin-right: 0.5rem;
}

