.photo {
  border-radius: 3rem;
  width: 100%;
  min-width: 25rem;
  height: auto;
  cursor: default;
  margin-bottom: 3rem;
}
.photo__block {
  margin-bottom: 2.5rem;
  position: sticky;
  top: 2rem;
  align-self: start;
}
@media (max-width: 600px) {
  .photo__block {
    position: relative;
    margin-bottom: 5rem;
  }
}

