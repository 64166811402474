.step {
  width: 100%;
  margin: 0;
  cursor: pointer;
  background: none;
  display: block;
}
.step:hover .step__line {
  background: #0C7ADF;
}
.step:hover .inactive {
  background: #818181;
}
.step:hover .step__text {
  color: #000000;
}
.step__text {
  color: #818181;
  font-size: 1.8rem;
  text-align: center;
  display: block;
  line-height: 120%;
  margin-bottom: 0.5rem;
  align-items: end;
}
.step__line {
  height: 1rem;
  width: 100%;
  border-radius: 3rem;
  background: #69B5FC;
  transition: all 0.2s;
}

.current {
  color: #000000;
}

.inactive {
  cursor: default;
}
.inactive .step__line {
  background: rgba(129, 129, 129, 0.5);
}
.inactive:hover .step__line {
  background: #818181;
}
.inactive:active {
  transform: translateY(0);
}

