@import '../../../styles/variables'

.text
  font-size: 2rem
  margin-bottom: 3rem
  &_small
    font-size: 2rem

.title
  color: $black
  margin-bottom: 1.5rem
  margin-top: 2rem
  font-size: 2.3rem
  &_small
    color: $black
    font-size: 2rem
