@import '../../../styles/variables'

.features
  grid-column: -2/-1
  grid-row: 1/-1
  align-self: start
  @media (max-width: 800px)
    grid-row: 2/3

.feature
  display: flex
  align-items: center
  &:not(:last-child)
    margin-bottom: 2.5rem
  &__icon
    margin-right: 1.5rem
    width: 2.5rem
    height: 2.5rem
  &__text
    font-family: 'FuturaDemiC', sans-serif
    color: $black
    font-size: 2rem
