.text {
  font-size: 2rem;
  margin-bottom: 3rem;
}
.text_small {
  font-size: 2rem;
}

.title {
  color: #000000;
  margin-bottom: 1.5rem;
  margin-top: 2rem;
  font-size: 2.3rem;
}
.title_small {
  color: #000000;
  font-size: 2rem;
}

