@import "../../../styles/variables"

.question__container
  display: grid
  grid-template-columns: 30rem 1fr
  grid-template-rows: 1fr max-content
  grid-column-gap: 2rem
  align-items: center
  margin-bottom: 3rem
  @media (max-width: 500px)
    grid-template-columns: 1fr
    grid-template-rows: max-content 1fr max-content
    grid-column-gap: 0
    grid-row-gap: 1rem

.big__question
  display: grid
  grid-template-columns: 1fr
  grid-template-rows: max-content 1fr max-content
  grid-row-gap: 1rem
  justify-items: stretch
  .question__form
    justify-self: stretch

.question
  color: $black
  font-size: 2.2rem
  @media (max-width: 500px)
    font-size: 2.5rem


.question__form
  justify-self: start
  width: 100%

.warning
  font-size: 1.5rem
  grid-row: -2/-1
  grid-column: -2/-1
  @media (max-width: 500px)
    grid-row: -2/-1
    grid-column: 1/2

.error
  color: $red
