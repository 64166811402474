.cards__advantages {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 2rem;
  margin-bottom: 10rem;
}
@media (max-width: 650px) {
  .cards__advantages {
    grid-template-columns: 1fr;
    grid-row-gap: 2rem;
  }
}

