.text {
  font-family: "FuturaDemiC", sans-serif;
  color: #000000;
  font-size: 2rem;
}

.icon {
  margin-right: 1.5rem;
  width: 2.5rem;
  height: 2.5rem;
}

.container {
  display: flex;
  align-items: center;
}
.container:not(:last-child) {
  margin-bottom: 2.5rem;
}

