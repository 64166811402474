.features {
  background: #E2EDF7;
  border-radius: 3rem;
  padding: 3rem 4rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(auto-fit, max-content);
  grid-gap: 2rem 1rem;
  margin-bottom: 3rem;
}
@media (max-width: 600px) {
  .features {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(auto-fit, max-content);
  }
}

