@import "../../../styles/variables"

.card
  border-radius: 3rem
  transition: all .5s
  @media (hover: hover) and (pointer: fine) and (pointer: fine)
    &:hover
      transform: scale(1.05)
  @media (max-width: 800px)
    text-align: center

.green
  background: $contrast
  padding: 3rem 4rem

.blue_light
  background: $blue-light
  padding: 2rem 2.5rem
  @media (hover: hover) and (pointer: fine)
    &:hover
      background: #cfe1f8


.h3
  font-size: 3.2rem
  font-weight: normal
  &__green
    color: $white
    font-size: 3.6rem
    margin-bottom: 4.5rem
    @media (max-width: 800px)
      margin-bottom: 2.5rem
  &__blue_light
    margin-bottom: 1.5rem
    font-size: 2.6rem

.p
  &__green
    color: $white
    font-size: 2rem
    margin-bottom: 7rem
    width: 80%
    @media (max-width: 800px)
      margin-bottom: 2.5rem
      width: 100%
  &__blue_light
    font-size: 2rem

.card
  &__1
    grid-column: 1/2
    grid-row: 1/-1
  &__2
    grid-column: 2/3
    grid-row: 1/2
  &__3
    grid-column: 2/3
    grid-row: 2/3

@media (max-width: 800px)
  .card
    &__1
      grid-column: 1/-1
      grid-row: 1/2
    &__2
      grid-column: 1/-1
      grid-row: 2/3
    &__3
      grid-column: 1/-1
      grid-row: -2/-1
