.modal
  display: none
  position: fixed
  z-index: 1
  left: 0
  top: 0
  width: 100vw
  height: 100vh
  overflow: hidden
  background-color: rgba(0,0,0,0.4)
  align-items: center
  justify-content: center
  &__title
    text-align: center
  &__header
    display: flex
    justify-content: end

.visible
  display: flex

.modal__content
  background-color: #fefefe
  margin: 15% auto
  padding: 20px
  border: 1px solid #888
  width: 80%
  border-radius: 2rem

.close
  color: #aaa
  float: right
  font-size: 4.5rem
  font-weight: bold


.close:hover, .close:focus
  color: black
  text-decoration: none
  cursor: pointer
