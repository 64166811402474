@import "../../../styles/variables"

.question
  display: flex
  justify-content: space-between
  align-items: center
  margin-bottom: 2.5rem
  cursor: pointer
  &__text
    margin-right: 1rem
    user-select: none
    font-weight: normal
    font-size: 3.2rem
    @media (max-width: 500px)
      font-size: 2.8rem
  &__icon
    width: 3rem
    height: 3rem
    transition: all .3s ease
    user-select: none

.answer
  max-height: 0
  opacity: 0.5
  transition: all .4s ease
  overflow: hidden
  padding: 0
  &__text
    width: 95%
    font-size: 2.3rem

.open
  max-height: max-content
  opacity: 1
  padding-bottom: 2.5rem
  display: block

.transform__icon
  transform: rotate(45deg)

.grid
  display: grid
  grid-template-columns: max-content 1fr max-content

.icon
  margin-right: 1rem
  width: 3rem
  height: 3rem

.small
  font-size: 2rem
