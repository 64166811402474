@media (max-width: 600px) {
  .symptoms {
    grid-column: 1/-1;
    grid-row: 4/5;
  }
}
.symptoms__container {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}
.symptoms__elem {
  border-radius: 3rem;
  border: 0.2rem solid #69B5FC;
  margin-bottom: 1rem;
}
.symptoms__elem:not(:last-child) {
  margin-right: 2rem;
}
.symptoms__elem_text {
  font-family: "FuturaDemiC", sans-serif;
  color: #000000;
  font-size: 2rem;
  padding: 0 2rem;
}

.title {
  color: #000000;
  margin-bottom: 1.5rem;
  font-size: 2.3rem;
}

