.loader
  width: 8rem
  height: 8rem
  border-radius: 50%
  position: relative
  animation: rotate 1s linear infinite
  display: block
  margin: 0 auto 2rem auto

.loader::before
  content: ""
  box-sizing: border-box
  position: absolute
  inset: 0
  border-radius: 50%
  border: .5rem solid #4B6DE6
  animation: prixClipFix 2s linear infinite


@keyframes rotate
  100%
    transform: rotate(360deg)


@keyframes prixClipFix
  0%
    clip-path: polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)
  25%
    clip-path: polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)
  50%
    clip-path: polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)
  75%
    clip-path: polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)
  100%
    clip-path: polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)
