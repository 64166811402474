@import '../../../styles/variables'

.psychologist_info
  display: grid
  grid-template-columns: 1fr 3fr
  grid-column-gap: 4rem
  margin-bottom: 8rem
  @media (max-width: 600px)
    grid-template-columns: 1fr
  hr
    border: none
    border-top: .1rem solid rgba($grey, .5)
    margin-bottom: 2.5rem

.psychologist__container
  min-height: calc(100vh - 39rem)

.loading
  display: flex
  align-items: center
  justify-content: center
