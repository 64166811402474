.psychologist_info {
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-column-gap: 4rem;
  margin-bottom: 8rem;
}
@media (max-width: 600px) {
  .psychologist_info {
    grid-template-columns: 1fr;
  }
}
.psychologist_info hr {
  border: none;
  border-top: 0.1rem solid rgba(129, 129, 129, 0.5);
  margin-bottom: 2.5rem;
}

.psychologist__container {
  min-height: calc(100vh - 39rem);
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

