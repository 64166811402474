.card {
  background: #ffffff;
  border-radius: 3rem;
  border: 0.1rem solid rgba(129, 129, 129, 0.25);
  box-shadow: 0 0.5rem 4rem 0 rgba(129, 129, 129, 0.25);
  padding: 2rem 1rem;
  width: 100%;
  transition: all 0.5s;
}
@media (hover: hover) and (pointer: fine) {
  .card:hover {
    transform: scale(1.04);
  }
}
.card__header {
  text-align: center;
  margin-bottom: 1rem;
  font-size: 3.2rem;
  font-weight: normal;
}
.card__text {
  text-align: center;
  font-size: 2rem;
}
.card__icon {
  margin: 0 auto 1rem auto;
  display: block;
  cursor: default;
  width: 4.5rem;
  height: 4.5rem;
}

