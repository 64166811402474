.phone__container {
  margin-bottom: 0;
  max-height: 0;
  opacity: 0.5;
  transition: all 0.4s ease;
  overflow: hidden;
}
.phone__text {
  font-size: 2rem;
  margin-bottom: 3rem;
}
.phone__header {
  display: flex;
}
.phone__header_text {
  font-size: 2rem;
  color: #000000;
  margin-right: 1rem;
}
.phone__header_icon {
  width: 2rem;
  height: auto;
}

.visible {
  display: block;
  max-height: 25rem;
  opacity: 1;
  margin-bottom: 3rem;
}

.warning {
  font-size: 1.5rem;
}

.error {
  color: #F54758;
}

