@import "../../../styles/variables"

.blue
  color: $main

.cards
  margin-bottom: 8rem
  display: grid
  grid-template-columns: repeat(2, 1fr)
  grid-template-rows: repeat(2, max-content)
  grid-row-gap: 3rem
  grid-column-gap: 2rem
  @media (max-width: 800px)
    grid-template-columns: 1fr
    grid-template-rows: repeat(3, max-content)

.header
  font-size: 5rem
  font-weight: normal
  font-family: "FuturaDemiC", sans-serif
  line-height: 6rem
  text-align: center
  margin-bottom: 8rem
