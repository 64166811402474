@import '../../../styles/variables'

.phone
  &__container
    margin-bottom: 0
    max-height: 0
    opacity: 0.5
    transition: all .4s ease
    overflow: hidden
  &__text
    font-size: 2rem
    margin-bottom: 3rem
  &__header
    display: flex
    &_text
      font-size: 2rem
      color: $black
      margin-right: 1rem
    &_icon
      width: 2rem
      height: auto

.visible
  display: block
  max-height: 25rem
  opacity: 1
  margin-bottom: 3rem

.warning
  font-size: 1.5rem

.error
  color: $red
