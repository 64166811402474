.custom__checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom__checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
  font-size: 2.2rem;
  font-family: "FuturaLightC", sans-serif;
  cursor: pointer;
}

.custom__checkbox + label::before {
  content: "";
  display: inline-block;
  width: 2.5rem;
  height: 2.5rem;
  flex-shrink: 0;
  flex-grow: 0;
  border: 0.1rem solid rgba(129, 129, 129, 0.5);
  border-radius: 0.5rem;
  margin-right: 1rem;
}

.custom__checkbox:not(:disabled):not(:checked) + label:hover::before {
  border-color: #69B5FC;
  background: linear-gradient(180deg, rgba(226, 237, 247, 0.25) 0%, rgba(105, 181, 252, 0.25) 100%);
}

.custom__checkbox:checked + label::before {
  border-color: #69B5FC;
  background: #E2EDF7 url("../../../static/images/checkbox.svg") no-repeat center center;
}

.checkbox__group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  align-items: center;
  margin-bottom: 3rem;
}
@media (max-width: 500px) {
  .checkbox__group {
    grid-template-columns: 1fr;
  }
}

.checkbox__field {
  margin-bottom: 1.5rem;
}

