.personal__questions {
  margin-bottom: 8rem;
  min-height: calc(100vh - 47rem);
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

