@import '../../../styles/variables'

.text
    font-size: 2rem
    &_bold
      font-family: FuturaDemiC, sans-serif
      color: $black

.title
  color: $black
  font-size: 2rem