@import '../../../styles/variables'

.input
  border-radius: 1rem
  border: .1rem solid $grey
  outline: none
  background: $white
  height: auto
  width: 100%
  padding: 1.5rem
  font-family: 'FuturaLightC', sans-serif
  font-size: 2.2rem
  &:focus
    border: .1rem solid $main
    box-shadow: 0 0 .4rem 0 $main

.valid
  padding: 1.5rem 4.5rem 1.5rem 1.5rem
  border: .1rem solid $contrast
  background: url("../../../static/images/valid.svg") no-repeat 96% center
  background-size: 2rem 2rem
  &:focus
    box-shadow: 0 0 .4rem 0 $contrast
    border: .1rem solid $contrast

.invalid
  padding: 1.5rem 4.5rem 1.5rem 1.5rem
  border: .1rem solid $red
  background: url("../../../static/images/invalid.svg") no-repeat 96% center
  background-size: 2rem 2rem
  &:focus
    box-shadow: 0 0 .4rem 0 $red
    border: .1rem solid $red

.number
  width: 16.6rem
  font-size: 2rem
