.mySwiper2
  width: calc(100vw - 7rem)

.slide__container
  display: flex
  justify-content: center
  align-items: center
  margin-bottom: 4rem

.photo_slider
  width: 40rem
  @media (max-width: 450px)
    width: 35rem
  @media (max-width: 370px)
    width: 30rem
