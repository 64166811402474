@import "../../../styles/variables"

.quote__container
  background: $blue-light
  padding: 7rem 0
  margin-bottom: 8rem
  @media (max-width: 1150px)
    padding: 7rem 2rem

.quote
  font-family: "FuturaLightC", sans-serif
  margin-bottom: 0
  font-size: 5rem
  font-weight: normal
  line-height: 6rem
  text-align: center
