@import "../../../styles/variables"

button
  font-family: "FuturaMediumC", sans-serif
  cursor: pointer
  transition: all .2s ease
  -webkit-transition-property: all
  -webkit-transition-duration: .2s
  -webkit-transition-timing-function: ease
  -moz-transition-property: all
  -moz-transition-duration: .2s
  -moz-transition-timing-function: ease
  border-radius: 3rem
  border: none
  &:active
    transform: translateY(.2rem)

.white
  background: transparent
  border: .1rem solid $black
  border-radius: 1rem
  padding: 1.1rem 1.4rem
  font-size: 1.9rem
  &:hover
    background: $black
    color: $white

.blue_big
  background: $main
  padding: 3rem 2rem
  width: 100%
  color: $white
  font-size: 3rem
  &:hover
    background: $main-dark

.blue, .blue_wide
  color: $white
  background: $main
  padding: 1.5rem 6rem
  font-size: 2.2rem
  margin: 5rem auto 0 auto
  display: block
  &:hover
    background: $main-dark

.blue_wide
  width: 100%
  margin: 0
  font-size: 2rem
  padding: 1.5rem 0

.green
  padding: 1.5rem 6rem
  font-size: 2.6rem
  color: $contrast-dark
  background: $white
  &:hover
    background: $contrast-dark
    color: $white
