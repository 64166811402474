.card {
  border-radius: 3rem;
  box-shadow: 0 0.5rem 4rem 0 rgba(129, 129, 129, 0.25);
  width: 100%;
  padding: 4rem 3rem;
  transition: all 0.5s;
}
@media (hover: hover) and (pointer: fine) {
  .card:hover {
    transform: scale(1.03);
  }
}
.card__header {
  width: max-content;
  border-radius: 4rem;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3rem 1rem 1rem;
  margin-bottom: 3.5rem;
}
.card__header_icon {
  margin-right: 1rem;
  cursor: default;
}
.card__header_text {
  font-size: 2.4rem;
}
.card__text {
  font-size: 3.2rem;
  font-weight: normal;
}

.card__blue {
  background: #69B5FC;
}
@media (hover: hover) and (pointer: fine) {
  .card__blue:hover {
    background: #0C7ADF;
  }
}
.card__green {
  background: #33B589;
}
@media (hover: hover) and (pointer: fine) {
  .card__green:hover {
    background: #237E5F;
  }
}
.card__white {
  background: #ffffff;
}
@media (hover: hover) and (pointer: fine) {
  .card__white:hover {
    background: rgba(129, 129, 129, 0.3);
  }
}

.card__header_white {
  border: 0.1rem solid #ffffff;
}
.card__header_black {
  border: 0.1rem solid #000000;
}

.white {
  color: #ffffff;
}

.black {
  color: #000000;
}

@media (max-width: 800px) {
  .card_2 {
    grid-row: -1/-2;
    grid-column: 1/2;
  }
  .card_4 {
    grid-row: 2/3;
    grid-column: 2/3;
  }
}
@media (max-width: 600px) {
  .card_2 {
    grid-row: 5/6;
    grid-column: 1/2;
  }
  .card_4 {
    grid-row: 3/4;
    grid-column: 1/2;
  }
}

