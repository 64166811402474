@import "variables"

*, ::after, ::before
  margin: 0
  padding: 0
  box-sizing: border-box

html
  font-size: 62.5%
  overflow-x: hidden
  @media (max-width: 1000px)
    font-size: 50%
  @media (max-width: 500px)
    font-size: 42%
  @media (min-width: 1800px)
    font-size: 70%

@font-face
  font-family: "FuturaLightC"
  src: url("../static/fonts/FuturaLightC.ttf")

@font-face
  font-family: "FuturaDemiC"
  src: url("../static/fonts/FuturaDemiC.ttf")

@font-face
  font-family: "FuturaMediumC"
  src: url("../static/fonts/FuturaMediumC.ttf")

body
  font-family: "FuturaMediumC", sans-serif
  color: $black
  overflow-x: hidden
  @media (hover: hover) and (pointer: fine)
    overflow-x: unset

.container
  max-width: 112rem
  margin: 0 auto
  position: relative
  @media (max-width: 1150px)
    padding: 0 2rem

.container_form
  max-width: 78rem
  margin: 0 auto
  position: relative
  @media (max-width: 800px)
    padding: 0 2rem

.background
  position: absolute
  top: 0
  right: -8rem
  z-index: -1
  cursor: default

p
  color: $grey
  line-height: 3rem

.text__light
  font-family: "FuturaLightC", sans-serif
  font-size: 3rem
  color: $black
  line-height: 120%

.margin_bottom_50px
  margin-bottom: 5rem

.margin_bottom_80px
  margin-bottom: 8rem

.hr
  border: none
  border-bottom: .1rem solid $grey

a
  text-decoration: none

.visible
  display: block

.invisible
  display: none
