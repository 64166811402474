button {
  font-family: "FuturaMediumC", sans-serif;
  cursor: pointer;
  transition: all 0.2s ease;
  -webkit-transition-property: all;
  -webkit-transition-duration: 0.2s;
  -webkit-transition-timing-function: ease;
  -moz-transition-property: all;
  -moz-transition-duration: 0.2s;
  -moz-transition-timing-function: ease;
  border-radius: 3rem;
  border: none;
}
button:active {
  transform: translateY(0.2rem);
}

.white {
  background: transparent;
  border: 0.1rem solid #000000;
  border-radius: 1rem;
  padding: 1.1rem 1.4rem;
  font-size: 1.9rem;
}
.white:hover {
  background: #000000;
  color: #ffffff;
}

.blue_big {
  background: #69B5FC;
  padding: 3rem 2rem;
  width: 100%;
  color: #ffffff;
  font-size: 3rem;
}
.blue_big:hover {
  background: #0C7ADF;
}

.blue, .blue_wide {
  color: #ffffff;
  background: #69B5FC;
  padding: 1.5rem 6rem;
  font-size: 2.2rem;
  margin: 5rem auto 0 auto;
  display: block;
}
.blue:hover, .blue_wide:hover {
  background: #0C7ADF;
}

.blue_wide {
  width: 100%;
  margin: 0;
  font-size: 2rem;
  padding: 1.5rem 0;
}

.green {
  padding: 1.5rem 6rem;
  font-size: 2.6rem;
  color: #237E5F;
  background: #ffffff;
}
.green:hover {
  background: #237E5F;
  color: #ffffff;
}

