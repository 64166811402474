@import "../../../styles/variables"

.symptoms
  @media (max-width: 600px)
    grid-column: 1/-1
    grid-row: 4/5
  &__container
    display: flex
    flex-wrap: wrap
    align-content: flex-start
  &__elem
    border-radius: 3rem
    border: .2rem solid $main
    margin-bottom: 1rem
    &:not(:last-child)
      margin-right: 2rem
    &_text
      font-family: 'FuturaDemiC', sans-serif
      color: $black
      font-size: 2rem
      padding: 0 2rem

.title
  color: $black
  margin-bottom: 1.5rem
  font-size: 2.3rem
