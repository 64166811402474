@import '../../../styles/variables'

.group
  display: inline-block
  overflow: hidden

.group__item
  display: inline-block
  float: left

.group input[type=radio]
  display: none

.group label
  display: inline-block
  cursor: pointer
  padding: 1.5rem 2rem
  border: .1rem solid $grey
  border-right: none
  user-select: none
  font-family: 'FuturaLightC', sans-serif
  font-size: 2.2rem
  transition: all .2s
  @media (max-width: 500px)
    font-size: 2.5rem

.group .group__item:first-child label
  border-radius: 1rem 0 0 1rem

.group .group__item:last-child label
  border-radius: 0 1rem 1rem 0
  border-right: .1rem solid $grey

/* Checked */
.group input[type=radio]:checked + label
  background: $main
  color: $white

/* Hover */
.group label:hover
  background: $blue-light

/* Disabled */
.group input[type=radio]:disabled + label
  background: $white
  color: $grey
