.quote__container {
  background: #E2EDF7;
  padding: 7rem 0;
  margin-bottom: 8rem;
}
@media (max-width: 1150px) {
  .quote__container {
    padding: 7rem 2rem;
  }
}

.quote {
  font-family: "FuturaLightC", sans-serif;
  margin-bottom: 0;
  font-size: 5rem;
  font-weight: normal;
  line-height: 6rem;
  text-align: center;
}

