.header {
  font-size: 2.7rem;
  margin-bottom: 1.5rem;
  font-weight: normal;
}

.text {
  font-size: 2.2rem;
  margin-bottom: 5rem;
}

.hr {
  border: none;
  border-bottom: 0.1rem solid rgba(129, 129, 129, 0.5);
}

.specialists {
  min-height: calc(100vh - 39rem);
}

.margin:last-child {
  margin-bottom: 8rem;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

