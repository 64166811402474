@import "../../../styles/variables"

.card
  border-radius: 3rem
  box-shadow: 0 .5rem 4rem 0 rgba(129, 129, 129, 0.25)
  width: 100%
  padding: 4rem 3rem
  transition: all .5s
  @media (hover: hover) and (pointer: fine)
    &:hover
      transform: scale(1.03)
  &__header
    width: max-content
    border-radius: 4rem
    background: transparent
    display: flex
    justify-content: space-between
    align-items: center
    padding: 1rem 3rem 1rem 1rem
    margin-bottom: 3.5rem
    &_icon
      margin-right: 1rem
      cursor: default
    &_text
      font-size: 2.4rem
  &__text
    font-size: 3.2rem
    font-weight: normal

.card
  &__blue
    background: $main
    @media (hover: hover) and (pointer: fine)
      &:hover
        background: $main-dark
  &__green
    background: $contrast
    @media (hover: hover) and (pointer: fine)
      &:hover
        background: $contrast-dark
  &__white
    background: $white
    @media (hover: hover) and (pointer: fine)
      &:hover
        background: rgba($grey, .3)

.card__header
  &_white
    border: .1rem solid $white
  &_black
    border: .1rem solid $black

.white
  color: $white

.black
  color: $black

@media (max-width: 800px)
  .card
    &_2
      grid-row: -1/-2
      grid-column: 1/2
    &_4
      grid-row: 2/3
      grid-column: 2/3

@media (max-width: 600px)
  .card
    &_2
      grid-row: 5/6
      grid-column: 1/2
    &_4
      grid-row: 3/4
      grid-column: 1/2
